import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Heading,
  Image,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { getMyCart } from '../redux/slices/cart';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import EmptyCategorySvg from '../assets/svg/empty-category.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';

import { showAStore } from '../redux/slices/store';
import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
  getDateWithTime,
  ucfirst,
} from '../helpers/helpers';
import { toggleDarkMode } from '../redux/slices/settings';
import { filterMyStoreOrder, singleOrder } from '../redux/slices/orders';

import WalletSvg from '../assets/svg/wallet.svg';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import { MdKeyboardArrowRight } from 'react-icons/md';

import { FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { withdrawFromWallet } from '../redux/slices/withdrawal';

import {
  useTonConnectModal,
  useTonWallet,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import { showAWallet, storeWallet } from '../redux/slices/wallet';

export default function Withdraw() {
  const { colorMode, toggleColorMode } = useColorMode();

  const { state, open, close } = useTonConnectModal();

  const wallet = useTonWallet();

  const location = useLocation();

  const toast = useToast();

  const bg = useColorModeValue('white', 'black');
  const color = useColorModeValue('white', 'gray.800');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const authState = useSelector(state => state.auth);

  const cartState = useSelector(state => state.cart);

  const withdrawState = useSelector(state => state.withdraw);

  const walletState = useSelector(state => state.wallet);

  const settingsState = useSelector(state => state.settings);

  const [input, setInput] = React.useState({ amount: '' });
  const [activeMenu, setActiveMenu] = React.useState('unshipped');

  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPrice, setCurrentPrice] = React.useState();

  const handleChange = event => {
    const { name, value } = event.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  React.useEffect(() => {
    fetchExchange();
  }, []);

  React.useEffect(() => {
    if (withdrawState?.error) {
      toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="red.500">
            {withdrawState?.error}
          </Box>
        ),
      });
    }
  }, [withdrawState?.error]);

  const fetchExchange = async () => {
    const exchange = await axios.get(
      'https://tonapi.io/v2/rates?tokens=ton&currencies=usd'
    );

    if (exchange?.status == 200) {
      setCurrentPrice(exchange?.data?.rates?.TON?.prices['USD']);
    }
  };

  const withdraw = () => {
    setIsLoading(true);
    if (Object.keys(walletState?.myWallet?.coin_details).length == 0) {
      dispatch(
        storeWallet({
          name: 'ton coin',
          symbol: 'TON',
          address: wallet?.account?.address,
        })
      );

      dispatch(
        showAWallet({
          user_id: authState?.user?.id,
        })
      );
    }

    setTimeout(() => {
      dispatch(
        withdrawFromWallet({
          amount: input?.amount,
          user_id: authState?.user?.id,
          payout_type: 'coin',
          payout_type_id:
            walletState?.myWallet?.coin_details[
              Object.keys(walletState?.myWallet?.coin_details)[0]
            ].id,
        })
      );
    }, 2000);

    navigate('/all-withdrawals');
  };

  const handleConnectWallet = () => {
    open();
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        //height={'100vh'}
        // pt={{ base: '120px', md: '75px' }}
        pb={{ base: '90px', md: '20px' }}
      >
        <Card
          p="10px"
          // bgColor={bg}
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/dashboard`)}
              variant="ghost"
              alignSelf={'flex-start'}
              _hover={{ bg: 'transparent' }}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>WITHDRAWAL</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Text
          fontSize={'14px'}
          lineHeight={'18px'}
          my={'20px'}
          fontWeight={'500'}
          color={'rgba(142, 139, 139, 1)'}
          px={{ base: '20px', md: '20px' }}
        >
          Balance
        </Text>

        <Flex
          mt={'5px'}
          mb={'25px'}
          direction={'row'}
          px={{ base: '20px', md: '20px' }}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text
            fontSize={'40px'}
            lineHeight={'60px'}
            fontWeight={'400'}
            alignItems={'flex-end'}
          >
            {formatNumberWithCommas(walletState?.myWallet?.balance ?? 0)}
            <span
              style={{
                marginLeft: '5px',
                color: 'rgba(142, 139, 139, 1)',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '30px',
              }}
            >
              {settingsState?.applicationSettings?.platform_currency
                ? settingsState?.applicationSettings?.platform_currency
                    ?.alphabetic_code
                : 'USD'}
            </span>
          </Text>
        </Flex>

        <Center px={{ base: '20px', md: '20px' }} flexDirection={'column'}>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            direction="row"
            mb={'25px'}
          >
            <Text
              fontSize={'10px'}
              lineHeight={'12px'}
              fontWeight={'400'}
              textAlign={'center'}
              color={'rgba(155, 155, 155, 1)'}
            >
              "Enter the amount and proceed with the withdrawal. Transaction
              processing may take 10-15 minutes."
            </Text>
          </Flex>

          {walletState?.myWallet?.coin_details &&
          Object.keys(walletState?.myWallet?.coin_details).length > 0 ? (
            <Card
              mt={10}
              mb={8}
              p={4}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
                <Image src={WalletSvg} mr={3} />

                <Text
                  noOfLines={1}
                  fontSize={'12px'}
                  lineHeight={'18px'}
                  width={'90%'}
                  fontWeight={'500'}
                >
                  {autoEllipses(
                    walletState?.myWallet?.coin_details[
                      Object.keys(walletState?.myWallet?.coin_details)[0]
                    ].address,
                    500
                  )}
                </Text>
              </Box>
            </Card>
          ) : (
            <>
              <Card
                mt={10}
                p={4}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
                onClick={() => handleConnectWallet()}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  flexDirection={'row'}
                >
                  <Image src={WalletSvg} mr={3} />

                  <Text
                    noOfLines={1}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    width={'90%'}
                    fontWeight={'500'}
                  >
                    {wallet?.account?.address
                      ? wallet?.account?.address.substring(0, 25) + '...'
                      : 'Connect to receive payment'}
                  </Text>
                </Box>

                <Box>
                  <MdKeyboardArrowRight
                    size={30}
                    color={
                      colorMode == 'light' ? 'black' : 'rgba(155, 155, 155, 1)'
                    }
                  />
                </Box>
              </Card>

              <Text
                fontSize={'10px'}
                fontWeight={'400'}
                lineHeight={'12px'}
                color={'rgba(52, 75, 103, 1)'}
                mt={2}
                mb={8}
                alignSelf={'flex-start'}
              >
                Easily receive payments by connecting your wallet when setting
                up your store. Start earning instantly!
              </Text>
            </>
          )}

          <Card
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pr={4}
            width={'100%'}
          >
            <Input
              type="number"
              height={'56px'}
              width={'100%'}
              pl={5}
              variant="unstyled"
              fontSize={'12px'}
              lineHeight={'18px'}
              fontWeight={'500'}
              name="amount"
              borderColor={'rgba(208, 207, 207, 1)'}
              // width={{ sm: '340px', md: '382px' }}
              placeholder={`Amount (${
                settingsState?.applicationSettings?.platform_currency
                  ? settingsState?.applicationSettings?.platform_currency
                      ?.alphabetic_code
                  : 'USD'
              })`}
              _placeholder={{ color: 'gray.500' }}
              value={input.amount}
              onChange={handleChange}
            />

            <Popover>
              <PopoverTrigger>
                <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Withdrawal Price</PopoverHeader>
                <PopoverBody fontSize={'12px'}>
                  Set the amount you wish to withdraw. The currency is{' '}
                  {settingsState?.applicationSettings?.platform_currency
                    ? settingsState?.applicationSettings?.platform_currency
                        ?.alphabetic_code
                    : 'USD'}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Card>
        </Center>

        <Box my={10} px={{ base: '20px', md: '20px' }}>
          {currentPrice && (
            <Text
              fontWeight={'400'}
              fontSize={'10px'}
              lineHeight={'18px'}
              color={'rgba(142, 139, 139, 1)'}
            >
              {`${currentPrice.toFixed(2)} USD = 1 TON`}
            </Text>
          )}
        </Box>

        <Center width={'100%'} px={{ base: '20px', md: '20px' }}>
          <Button
            mt={4}
            alignSelf={'center'}
            borderRadius={'16px'}
            width={'100%'}
            height={'54px'}
            backgroundColor={!input?.amount ? 'gray' : 'rgba(36, 107, 253, 1)'}
            padding={2}
            isLoading={isLoading}
            isDisabled={
              !input?.amount ||
              (!wallet?.account?.address &&
                Object.keys(walletState?.myWallet?.coin_details).length == 0)
            }
            onClick={() => withdraw()}
            loadingText="processing..."
          >
            <Text
              fontSize="16px"
              fontWeight="400"
              lineHeight="26px"
              color={!input?.amount ? 'black' : 'white'}
            >
              Withdraw Funds
            </Text>
          </Button>
        </Center>
      </Flex>
    </>
  );
}
