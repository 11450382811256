import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getToken = createAsyncThunk('auth/getToken', async () => {
  const res = await fetch(`${apiUrl}/token`);

  const data = await res.json();

  return data.token;
});

export const register = createAsyncThunk(
  'auth/register',
  async (data, thunkAPI) => {
    const store_name = data['store_name'];
    const store_phone = data['store_phone'];
    const store_description = data['store_description'];

    delete data['store_name'];
    delete data['store_phone'];
    delete data['store_description'];

    const formData = new FormData();

    let userInfo;

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/auth/register`, {
      method: 'POST',

      body: formData,
    });
    const response = await res.json();

    if (response.status_code == 201) {
      const login = await fetch(`${apiUrl}/auth/login`, {
        method: 'POST',

        body: formData,
      });

      const loginResponse = await login.json();

      const token = loginResponse.data?.access_token;

      userInfo = loginResponse.data;

      const store_data = {
        store_name,
        store_email: data['email'],
        store_phone,
        store_address: data['name'],
        store_city: 'Enugu',
        store_state: 'Enugu',
        store_country: 'Nigeria',
        store_description,

        business_email: data['email'],
        business_name: store_name,
        business_phone: store_phone,
        business_address: data['name'],
        business_description: store_description,
        business_city: 'Enugu',
        business_state: 'Enugu',
        business_country: 'Nigeria',
        business_type: 'store',
      };

      const storeFormData = new FormData();

      Object.keys(store_data).forEach(key => {
        storeFormData.append(key, store_data[key]);
      });

      const res = await fetch(`${apiUrl}/onboard/business`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        body: storeFormData,
      });
      const response = await res.json();

      const newLogin = await fetch(`${apiUrl}/auth/login`, {
        method: 'POST',

        body: formData,
      });

      const newLoginResponse = await newLogin.json();

      userInfo = newLoginResponse.data;
    }
    return userInfo;
  }
);

export const login = createAsyncThunk('auth/login', async (data, thunkAPI) => {
  const formData = new FormData();

  let callback = () => {};

  if (data?.callback) {
    callback = data.callback;

    delete data.callback;
  }

  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  const res = await fetch(`${apiUrl}/auth/login`, {
    method: 'POST',

    body: formData,
  });

  const response = await res.json();

  console.log(response);

  if (response?.data) {
    // Call the callback if provided

    callback(response.data);

    return response?.data;
  } else {
    callback({});
    return thunkAPI.rejectWithValue(response);
  }
});

export const registerCustomer = createAsyncThunk(
  'auth/registerCustomer',
  async (data, thunkAPI) => {
    const formData = new FormData();

    let callback = () => {};

    if (data.callback) {
      callback = data.callback;

      delete data.callback;
    }

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/auth/register`, {
      method: 'POST',

      body: formData,
    });
    const response = await res.json();

    console.log('my registter resppppp', response);

    if (response.status_code == 201) {
      callback(response.data);

      return response?.data;
    } else {
      callback({});
      return thunkAPI.rejectWithValue('Invalid Register');
    }
  }
);

export const getMyProfile = createAsyncThunk(
  'auth/getMyProfile',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    const res = await fetch(`${apiUrl}/profile/me?properties=1`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,

        'Content-Type': 'application/json',
      },
    });
    const response = await res.json();

    if (response.status_code == 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue('Invalid getting profile');
    }
  }
);

export const registerCompany = createAsyncThunk(
  'auth/registerCompany',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    let callback = () => {};

    if (data.callback) {
      callback = data.callback;

      delete data.callback;
    }

    const store_name = data['store_name'];
    const store_phone = data['store_phone'];
    const store_description = data['store_description'];

    delete data['store_name'];
    delete data['store_phone'];
    delete data['store_description'];

    const store_data = {
      store_name,
      store_email: data['email'],
      store_phone,
      store_address: data['name'],
      store_city: 'Enugu',
      store_state: 'Enugu',
      store_country: 'Nigeria',
      store_description,

      business_email: data['email'],
      business_name: store_name,
      business_phone: store_phone,
      business_address: data['name'],
      business_description: store_description,
      business_city: 'Enugu',
      business_state: 'Enugu',
      business_country: 'Nigeria',
      business_type: 'store',
    };

    const storeFormData = new FormData();

    Object.keys(store_data).forEach(key => {
      storeFormData.append(key, store_data[key]);
    });

    const res = await fetch(`${apiUrl}/onboard/business`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: storeFormData,
    });
    const response = await res.json();

    callback();

    return response;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    profile: {},
    token: '',
    team: '',
    reload: false,
    isLoading: false,
    createUser: false,
    customerSide: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getToken.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reload = !state.reload;
      state.token = action.payload;
    });
    builder.addCase(getToken.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(register.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerSide = true;
      state.reload = !state.reload;
      state.user = action?.payload;
      state.token = action.payload?.access_token;
      state.team = action.payload?.team_roles
        ? Object.keys(action.payload?.team_roles)[0]
        : '';
      state.createUser = false;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.fails;
    });
    builder.addCase(registerCustomer.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(registerCustomer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerSide = true;
      state.reload = !state.reload;
      state.user = action?.payload;
      state.token = action.payload?.access_token;
      state.team = action.payload?.team_roles
        ? Object.keys(action.payload?.team_roles)[0]
        : '';
      state.createUser = false;
    });
    builder.addCase(registerCustomer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.fails;
    });
    builder.addCase(login.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload?.status_code == 422) {
        state.createUser = true;
        state.isLoading = false;
        return;
      }
      state.isLoading = false;
      state.reload = !state.reload;
      state.user = action.payload;
      state.token = action.payload?.access_token;

      state.team = action.payload?.team_roles
        ? Object.keys(action.payload?.team_roles)[0]
        : '';
    });
    builder.addCase(login.rejected, (state, action) => {
      console.log(action);
      if (action.payload?.status_code == 422) {
        state.createUser = true;
        state.isLoading = false;
        return;
      }
      state.isLoading = false;
      state.error = action.payload.message;
    });

    builder.addCase(getMyProfile.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMyProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
    });
    builder.addCase(getMyProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default authSlice.reducer;
