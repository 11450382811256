import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Divider,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Heading,
  Image,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { getMyCart } from '../redux/slices/cart';

import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import EmptyCategorySvg from '../assets/svg/empty-category.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';

import { showAStore } from '../redux/slices/store';
import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
  getDateWithTime,
  ucfirst,
} from '../helpers/helpers';
import { toggleDarkMode } from '../redux/slices/settings';
import { filterMyStoreOrder, singleOrder } from '../redux/slices/orders';

import { MdSearch } from 'react-icons/md';
import { getMyWallet, showAWallet } from '../redux/slices/wallet';
import {
  getWalletTransactions,
  singleTransaction,
} from '../redux/slices/withdrawal';

export default function AllWithdrawals() {
  const { colorMode, toggleColorMode } = useColorMode();

  const location = useLocation();

  const bg = useColorModeValue('white', 'black');
  const color = useColorModeValue('white', 'gray.800');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const authState = useSelector(state => state.auth);

  const settingsState = useSelector(state => state.settings);

  const walletState = useSelector(state => state.wallet);

  const [foodMenu, setFoodMenu] = React.useState([]);
  const [activeMenu, setActiveMenu] = React.useState('unshipped');

  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    // dispatch(getMyWallet());
    dispatch(
      showAWallet({
        user_id: authState?.user?.id,
      })
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const showWithdrawal = transaction => {
    dispatch(singleTransaction(transaction));
    navigate('/single-withdrawal');
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        //height={'100vh'}
        // pt={{ base: '120px', md: '75px' }}
        pb={{ base: '90px', md: '20px' }}
      >
        <Card
          p="10px"
          // bgColor={bg}
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/dashboard`)}
              variant="ghost"
              alignSelf={'flex-start'}
              _hover={{ bg: 'transparent' }}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>Withdrawals</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Text
          fontSize={'14px'}
          lineHeight={'18px'}
          my={'20px'}
          fontWeight={'500'}
          color={'rgba(142, 139, 139, 1)'}
          px={{ base: '20px', md: '20px' }}
        >
          Balance
        </Text>

        <Flex
          mt={'5px'}
          mb={'25px'}
          direction={'row'}
          px={{ base: '20px', md: '20px' }}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text
            fontSize={'40px'}
            lineHeight={'60px'}
            fontWeight={'400'}
            alignItems={'flex-end'}
          >
            {formatNumberWithCommas(walletState?.myWallet?.balance ?? 0)}
            <span
              style={{
                marginLeft: '5px',
                color: 'rgba(142, 139, 139, 1)',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '30px',
              }}
            >
              {settingsState?.applicationSettings?.platform_currency
                ? settingsState?.applicationSettings?.platform_currency
                    ?.alphabetic_code
                : 'USD'}
            </span>
          </Text>

          <Button
            alignSelf={'center'}
            borderRadius={'8px'}
            width={'100px'}
            height={'34px'}
            backgroundColor={
              parseFloat(walletState?.myWallet?.balance ?? 0) == 0
                ? 'gray'
                : 'rgba(36, 107, 253, 1)'
            }
            // padding={2}

            onClick={() => {
              parseFloat(walletState?.myWallet?.balance ?? 0) != 0 &&
                navigate('/withdraw');
            }}
          >
            <Text
              fontSize="14px"
              fontWeight="700"
              lineHeight="18.23px"
              color={'white'}
            >
              Withdraw
            </Text>
          </Button>
        </Flex>

        <Center flexDirection={'column'}>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            direction="row"
            mb={'35px'}
            px={{ base: '20px', md: '20px' }}
          >
            <Text
              fontSize={'14px'}
              lineHeight={'23px'}
              fontWeight={'400'}
              textAlign={'center'}
              color={'rgba(155, 155, 155, 1)'}
            >
              "Please note that withdrawals may take 2 working days to be
              processed."
            </Text>
          </Flex>

          {(walletState?.myWallet?.wallet_transaction ?? []).length > 0 && (
            <Box
              borderBottomColor={'rgba(36, 107, 253, 1)'}
              borderBottomWidth={'4px'}
              borderBottomLeftRadius={'2px'}
              borderBottomRightRadius={'2px'}
              paddingBottom={'5px'}
              marginRight={'20px'}
              mb={5}
            >
              <Text
                color={'rgba(36, 107, 253, 1)'}
                fontWeight={'500'}
                fontSize={'15px'}
              >
                {'Recent Activity'}
              </Text>
            </Box>
          )}

          {isLoading ? (
            <Skeleton
              noOfLines={1}
              mb={2}
              spacing="4"
              height={'50px'}
              width={'90%'}
              borderRadius={'5px'}
            />
          ) : (
            <Flex
              alignItems={'center'}
              flexDirection={'column'}
              maxH={'50vh'}
              width={'100%'}
              p={0}
              m={0}
              overflowY={'auto'}
            >
              {walletState?.myWallet?.wallet_transaction &&
                (walletState?.myWallet?.wallet_transaction ?? []).length > 0 &&
                walletState?.myWallet?.wallet_transaction.map((item, index) => (
                  <Card
                    key={index}
                    width={'90%'}
                    py={4}
                    px={4}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mb={3}
                    onClick={() => showWithdrawal(item)}
                  >
                    <Flex>
                      <Text
                        fontSize={'43px'}
                        lineHeight={'40px'}
                        fontWeight={'400'}
                        mr={2}
                        color={
                          item?.status == 'success'
                            ? 'rgba(0, 210, 32, 1)'
                            : item?.status == 'pending' || 'initiated'
                            ? 'rgba(255, 169, 39, 1)'
                            : 'rgba(229, 20, 0, 1)'
                        }
                      >
                        {getDateWithTime(new Date(item?.created_at)).hours12}
                      </Text>
                      <Flex alignItems={'center'} direction={'column'}>
                        <Text
                          fontSize={'14px'}
                          lineHeight={'20px'}
                          fontWeight={'400'}
                          color={
                            item?.status == 'success'
                              ? 'rgba(0, 210, 32, 1)'
                              : item?.status == 'pending'
                              ? 'rgba(255, 169, 39, 1)'
                              : 'rgba(229, 20, 0, 1)'
                          }
                        >
                          {getDateWithTime(new Date(item?.created_at)).minutes}
                        </Text>
                        <Text
                          fontSize={'14px'}
                          lineHeight={'20px'}
                          fontWeight={'400'}
                          color={'rgba(142, 139, 139, 0.8)'}
                        >
                          {getDateWithTime(new Date(item?.created_at)).ampm}
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex direction={'column'}>
                      <Text
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        fontWeight={'400'}
                      >
                        {formatDate(new Date(item?.created_at))}
                      </Text>

                      <Text
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        fontWeight={'400'}
                        color={'rgba(142, 139, 139, 0.8)'}
                      >
                        {autoEllipses(authState?.user?.email)}
                      </Text>
                    </Flex>

                    <Text
                      fontSize={'14px'}
                      lineHeight={'18px'}
                      fontWeight={'400'}
                    >
                      {formatNumberWithCommas(item?.amount)}{' '}
                      <span style={{ color: 'rgba(142, 139, 139, 0.8)' }}>
                        {settingsState?.applicationSettings?.platform_currency
                          ? settingsState?.applicationSettings
                              ?.platform_currency?.alphabetic_code
                          : 'USD'}
                      </span>
                    </Text>
                  </Card>
                ))}
            </Flex>
          )}
        </Center>
      </Flex>
    </>
  );
}
