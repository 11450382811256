import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  extendTheme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import {
  Routes,
  Route,
  Router,
  useLocation,
  useParams,
} from 'react-router-dom';
import Dashboard from './screens/Dashboard';
import CreateStore from './screens/CreateStore';
import UploadProduct from './screens/UploadProduct';
import AllProducts from './screens/AllProducts';
import BotSetup from './screens/BotSetup';
import Marketplace from './screens/Marketplace';
import Cart from './screens/Cart';

import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Product from './screens/Product';
import FixedBottomTab from './components/BottomTab';
import Profile from './screens/Profile';
import Community from './screens/Community';
import CustomerCreateStore from './screens/CustomerCreateStore';
import Checkout from './screens/Checkout';
import PaymentSuccess from './screens/PaymentSuccess';
import SwitchAccount from './screens/SwitchAccount';
import SelectCategory from './screens/SelectCategory';
import MyStoreOrders from './screens/MyStoreOrders';
import EditProduct from './screens/EditProduct';
import OrderDetails from './screens/OrderDetails';
import CustomerOrderDetails from './screens/CustomerOrderDetails';
import FaqPage from './screens/FaqPage';
import NotFound from './screens/NotFound';
import PaymentMethod from './screens/PaymentMethod';
import TermsConditionsPage from './screens/TermsAndConditions';
import AllWithdrawals from './screens/AllWithdrawals';
import WithdrawalDetails from './screens/WithdrawalDetails';
import Withdraw from './screens/Withdraw';
import FinalizeWithdrawal from './screens/FinalizeWithdrawal';

function App() {
  const { id } = useParams();
  const pathsWithBottomTab = [
    `store`,
    'cart',
    'product',
    'profile',
    'community',
    'payment',
    'switch',
    'customer-order-details',
  ];
  const location = useLocation();

  const getActiveFromPathname = pathname => {
    const parts = pathname.split('/');
    return parts.length > 2 ? parts[1] : null;
  };

  const showBottomTab = pathsWithBottomTab.includes(
    getActiveFromPathname(location.pathname)
  );

  const breakpoints = {
    base: '0px',
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  };

  const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  };

  const theme = extendTheme({
    fonts: {
      body: `'Poppins', sans-serif`,
      heading: `'Poppins', sans-serif`,
      mono: `'Poppins', monospace`,
    },
    breakpoints,
    config,
  });

  return (
    <ChakraProvider theme={theme}>
      <TonConnectUIProvider manifestUrl="https://server.notstore.cc/assets/manifests/tonconnect-manifest.json">
        <Routes>
          <Route element={<Dashboard />} path="/" />
          <Route element={<Dashboard />} path="/dashboard" />
          <Route element={<CreateStore />} path="/create-store" />
          <Route element={<UploadProduct />} path="/upload-product" />
          <Route element={<AllProducts />} path="/products" />
          <Route element={<BotSetup />} path="/bot-setup" />
          <Route element={<Marketplace />} path="/store/:id" />
          <Route element={<Cart />} path="/cart/:id" />
          <Route element={<Checkout />} path="/checkout/:id" />
          <Route element={<PaymentMethod />} path="/payment-method/:id" />
          <Route element={<Product />} path="/product/:id" />
          <Route element={<Profile />} path="/profile/:id" />
          <Route element={<Community />} path="/community/:id" />
          <Route element={<Profile />} path="/store-profile" />
          <Route element={<Community />} path="/store-community" />
          <Route element={<CustomerCreateStore />} path="/new/:id" />
          <Route element={<PaymentSuccess />} path="/payment/:id" />
          <Route element={<SwitchAccount />} path="/switch/:id" />
          <Route element={<SelectCategory />} path="/select-category" />
          <Route element={<MyStoreOrders />} path="/store-orders" />
          <Route element={<EditProduct />} path="/edit-product" />
          <Route element={<OrderDetails />} path="/order-details" />
          <Route element={<TermsConditionsPage />} path="/terms/:id" />
          <Route element={<TermsConditionsPage />} path="/terms-store" />
          <Route element={<AllWithdrawals />} path="/all-withdrawals" />
          <Route element={<WithdrawalDetails />} path="/single-withdrawal" />
          <Route element={<Withdraw />} path="/withdraw" />
          <Route element={<FinalizeWithdrawal />} path="/finalize-withdrawal" />
          <Route
            element={<CustomerOrderDetails />}
            path="/customer-order-details/:id"
          />
          <Route
            element={<CustomerOrderDetails />}
            path="/store-order-details"
          />
          <Route element={<FaqPage />} path="/faq" />
          <Route element={<NotFound />} path="*" /> {/* Not Found route */}
        </Routes>
        {showBottomTab && <FixedBottomTab />}
      </TonConnectUIProvider>
    </ChakraProvider>
  );
}

export default App;
